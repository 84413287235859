import Label from 'DesignSystem/Typography/Label/Label';
import React, { useCallback, useState } from 'react';
import { styled } from '../../../Theme/stitches.config';

type InputType = {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  autoComplete?: string;
  error?: boolean;
  errorText?: string;
  maxLength?: number;
};

const Input = ({
  title,
  type = 'Text',
  name,
  onChange,
  onBlur,
  onKeyDown,
  disabled,
  placeholder,
  defaultValue,
  autoComplete,
  error = false,
  errorText,
  maxLength,
}: InputType) => {
  const [focus, setFocus] = useState<boolean>(false);

  const handleChange = useCallback(
    (value: string) => {
      onChange && onChange(value);
    },
    [onChange]
  );

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    handleChange(e.currentTarget.value);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = () => {
    setFocus(true);
  };

  return (
    <InputGroup>
      {title && title !== '' && (
        <Label error={error} disabled={disabled}>
          {title}
        </Label>
      )}

      <FormInput
        aria-label={title || title !== '' ? title : 'labeltext'}
        type={type}
        error={error}
        onChange={(e) => handleChange(e.currentTarget.value)}
        {...(onKeyDown && { onKeyDown })}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        name={name}
        placeholder={!focus ? placeholder : ''}
        disabled={disabled}
        defaultValue={defaultValue}
        maxLength={maxLength || 1000}
        autoComplete={autoComplete}
      />
      {error && errorText && <Label error={error}>{errorText}</Label>}
    </InputGroup>
  );
};

export const InputGroup = styled('div', {
  position: 'relative',
  maxW: '640px',
  width: '100%',
  mb: 2,
});

export const FormInput = styled('input', {
  lineHeight: 'lh1125',
  fontSize: '$fontSizes$fontSize100',
  w: '100%',
  h: '48px',
  px: '$s100',
  py: '$s75',
  my: '$s25',
  borderRadius: '$radius$rExtraSmall',
  outline: '$borders$default solid $decorationEmphasized',
  backgroundColor: '$white',
  color: '$onSurface',

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&::placeholder': {
    color: '$onSurfaceDisabled',
  },
  '&:disabled': {
    background: '$surfaceVariant',
    cursor: 'not-allowed',
  },
  '&:focus-visible': {
    borderLeftWidth: '0px',
    outline: 'solid 2px $focusBorder',
    boxShadow: 'none',
    outlineOffset: '-2px',
  },

  variants: {
    error: {
      true: {
        borderLeft: '3px solid $semanticError',
        outline: '$borders$thick solid $semanticError',
        boxShadow: 'none',

        '&:focus-visible': {
          borderLeftWidth: '3px solid $semanticError',
          outline: '$borders$thick solid $semanticError',
          boxShadow: 'none',
        },
      },
    },
  },
});

export default Input;
