import { CSS } from '@stitches/react';
import { RegularLabel, ErrorLabel } from './BaseLabelStyle';
type PropTypes = {
  children: React.ReactNode;
  css?: CSS;
  interactive?: boolean;
  bold?: boolean;
  disabled?: boolean;
  error?: boolean;
  htmlFor?: string;
  id?: string;
  role?: string;
  focusWithin?: boolean;
};
const Label = ({
  children,
  css,
  interactive,
  bold,
  disabled,
  error,
  htmlFor,
  id,
  role,
  focusWithin,
}: PropTypes) => {
  const StyledLabel = error ? ErrorLabel : RegularLabel;
  return (
    <StyledLabel
      css={css}
      interactive={interactive}
      bold={bold}
      disabled={disabled}
      error={error}
      htmlFor={htmlFor}
      id={id}
      role={role}
      focusWithin={focusWithin}
    >
      {children}
    </StyledLabel>
  );
};
export default Label;
