import { CSS } from '@stitches/react';
import { ReactNode } from 'react';
import { styled } from 'Theme/stitches.config';

type PropType = {
  col?: colSizes;
  children: ReactNode;
  css?: CSS;
};

export type colSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

function GridColumn({ col = 12, children, css }: PropType) {
  return (
    <GridColumnContainer col={col} css={css}>
      {children}
    </GridColumnContainer>
  );
}

const GridColumnContainer = styled('div', {
  display: 'flex',
  g: 4,
  '@bpMin376': { g: 6 },
  variants: {
    col: {
      1: { gridColumnEnd: 'span 1' },
      2: { gridColumnEnd: 'span 2' },
      3: {
        gridColumnEnd: 'span 4',
        '@bpMin376': {
          gridColumnEnd: 'span 3',
        },
      },
      4: {
        gridColumnEnd: 'span 4',
      },
      5: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 5',
        },
      },
      6: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 6',
        },
      },
      7: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 6',
        },
      },
      8: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 8',
        },
      },
      9: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 6',
        },
      },
      10: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 6',
        },
      },
      11: {
        gridColumnEnd: 'span 4',
        '@bpMin721': {
          gridColumnEnd: 'span 6',
        },
      },
      12: {
        gridColumnEnd: 'span 4',
        '@bpMin376': {
          gridColumnEnd: 'span 8',
        },
        '@bpMin721': {
          gridColumnEnd: 'span 12',
        },
      },
    },
  },
});

export default GridColumn;
