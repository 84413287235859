import { styled } from 'Theme/stitches.config';

type Props = { items: number; position?: 'absolute' | 'relative' };

function ItemsNotifier({ items, position = 'absolute' }: Props) {
  if (items < 1) return <></>;
  return (
    <ItemsNotifierContainer absolutePosition={position === 'absolute'}>
      {items}
    </ItemsNotifierContainer>
  );
}

const ItemsNotifierContainer = styled('span', {
  backgroundColor: '$interactiveNotifierBackgroundDefault',
  color: '$onInteractivePrimary',
  fontFamily: 'fontMedium',
  borderRadius: '50%',
  fs: 6,
  px: '5px',
  minW: 18,
  minH: 18,
  lineHeight: '$lh16',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'hidden',
  zIndex: '$Notification',
  variants: {
    absolutePosition: {
      true: {
        position: 'absolute',
        b: -1,
        r: -2,
      },
      false: {
        height: 'fit-content',
        mr: 4,
      },
    },
  },
});

export default ItemsNotifier;
