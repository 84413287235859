import { styled } from '@stitches/react';
import Arrow from 'DesignSystem/Icons/Icons/Arrow';
import SiteLogo from 'DesignSystem/Icons/Icons/SiteLogo';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

type PropTypes = {
  showBackArrow?: boolean;
  backArrowLabel?: string | never;
  backArrowOnClick?: () => void;
};

const CheckoutHeader = ({
  showBackArrow,
  backArrowLabel,
  backArrowOnClick,
}: PropTypes) => {
  const logoUrl = canUseDOM() ? localStorage.getItem('logoUrl') : '';

  return (
    <Root>
      {showBackArrow && (
        <button onClick={backArrowOnClick} aria-label={backArrowLabel}>
          <Arrow color="primary" size="m" />
        </button>
      )}
      {logoUrl && (
        <InteractableContentLink href={`/`} css={{ mx: 'auto' }}>
          <SiteLogo src={logoUrl} width={100} />
        </InteractableContentLink>
      )}
    </Root>
  );
};

const Root = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  paddingLeft: '$s400',
  paddingRight: '$s400',
  borderBottom: '1px solid $staticBorderDefault',
  '@media (max-width: 720px)': {
    paddingLeft: '$s150',
    paddingRight: '$s150',
  },
});

export default CheckoutHeader;
