import { useState } from 'react';

const useAccordionMode = () => {
  const [openedIndex, setOpenedIndex] = useState<any>(null);

  const handleToggle = (index: number) => {
    if (index === openedIndex) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  };

  return [openedIndex, setOpenedIndex, handleToggle];
};

export default useAccordionMode;
