import { useEffect, useState } from 'react';
import { styled } from 'Theme/stitches.config';
import QuantityStepper, {
  QuantityType,
} from 'Commerce/Atom/QuantityStepper/QuantityStepper';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Grid from 'DesignSystem/Grids/Grid';
import GridColumn from 'DesignSystem/Grids/GridColumn';
import { debounce } from 'Shared/Common/debounce';
import { RemoveFromCart, SetQuantity, SetSelectedIssue } from '../Cart';

import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { Trash } from 'DesignSystem/Icons';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import ProductCardImage from 'Commerce/Molecules/ProductCard/ProductCardImage';

export interface MiniCartProductProps {
  item: VariationModel;
  inCheckout?: boolean;
  readonly?: boolean;
}

const MiniCartProduct = ({
  item,
  inCheckout = false,
  readonly = false,
}: MiniCartProductProps) => {
  const {
    code,
    image,
    name,
    nameOfAdditionalItems,
    quantity,
    wasPrice,
    nowPrice,
    stock, // Not used in the Egmont Figma design
    productUrl,
    productType,
    selectableIssues,
    selectedIssue,
    premiumMainImage,
    additionalInfo,
  }: VariationModel = item;

  const {
    productLabels: { remove: removeLabel },
    cartLabels: { startDate },
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();
  const { modalState, toggleModal } = useUiState();
  const bpMin361 = useMedia(mediaQueryTypes.bpMin361);
  const [initQState, setInitQState] = useState<boolean>(false);
  const [quantityState, setQuantityState] = useState<QuantityType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startFromIsLoading, setStartFromIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!initQState && !quantityState) {
      setInitQState(true);
      setQuantityState(quantity);
    }

    if (quantityState && quantityState !== quantity && initQState) {
      debounce(() => {
        setIsLoading(true);
        SetQuantity(item, quantityState, quantity, languageRoute)
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }, 1000);
    }
  }, [quantityState]);

  useEffect(() => {
    if (quantity !== quantityState) {
      setQuantityState(quantity);
    }
  }, [quantity]);

  const quantityStepper = () => {
    if (readonly) return <div>{quantityState + ' st'}</div>;
    return (
      <QuantityStepper
        disabled={isLoading}
        code={code}
        minQuantity={1}
        maxQuantity={stock.stockQuantity}
        currentQuantity={quantityState}
        handleStepperIncrement={async () => {
          if (!quantityState) {
            setQuantityState(1);
            return;
          }
          setQuantityState((current) => {
            if (!current) return undefined;
            return current + 1;
          });
        }}
        handleStepperDecrement={async () => {
          setQuantityState((current) => {
            if (!current) return undefined;

            return current - 1;
          });
        }}
        handleInputChange={(quantityState) => {
          if (
            typeof quantityState === 'number' &&
            quantityState <= stock.stockQuantity
          ) {
            setQuantityState(quantityState);
          }

          if (typeof quantityState === 'undefined') {
            setQuantityState(undefined);
          }
        }}
        handleOnBlur={() => {
          if (!quantityState) {
            setQuantityState(quantity);
          }
        }}
        disabledMin={quantityState === undefined}
      />
    );
  };

  const price = () => {
    return (
      <Price>
        {wasPrice && !wasPrice.isZero && (
          <WasPrice>{wasPrice.priceWithSymbol}</WasPrice>
        )}
        <NowPrice>
          {nowPrice.priceAsString}{' '}
          <Currency>{nowPrice.currencySymbol}</Currency>
        </NowPrice>
      </Price>
    );
  };

  const handleStartFromChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStartFromIsLoading(true);
    await SetSelectedIssue(code, event.target.value, languageRoute);
    setStartFromIsLoading(false);
  };

  const subscriptionInfo = () => {
    return <SubscriptionInfo>{additionalInfo}</SubscriptionInfo>;
  };

  const startFrom = () => {
    if (readonly)
      return (
        <StartFrom>
          <StartFromInfo>
            {startDate}: {selectedIssue.displayName}
          </StartFromInfo>
          <SubscriptionInfo>{additionalInfo}</SubscriptionInfo>
        </StartFrom>
      );

    return (
      <StartFrom>
        <div>{startDate}</div>
        <SelectWrapper>
          <select
            onChange={handleStartFromChange}
            value={selectedIssue.id ?? 0}
            aria-label={startDate}
          >
            {selectableIssues.map((option, index) => (
              <option key={index} value={option.id}>
                {option.displayName}
              </option>
            ))}
          </select>
          {startFromIsLoading && (
            <AnimatedIcon animation={'spinAnimation'}>
              <LoadingCircle />
            </AnimatedIcon>
          )}
        </SelectWrapper>
        <SubscriptionInfo>{additionalInfo}</SubscriptionInfo>
      </StartFrom>
    );
  };

  const remove = () => {
    if (readonly) return <></>;
    return (
      <RemoveButton onClick={() => RemoveFromCart(item, languageRoute)}>
        <Trash size="s" color={'onInteractiveSecondary'} title={removeLabel} />
      </RemoveButton>
    );
  };

  const scalingPreset =
    item.productType.toLowerCase() == 'articleproduct'
      ? ImageScalingTypes.MINICART_PRODUCT
      : ImageScalingTypes.MINICART_MAGAZINE;

  return (
    <Card>
      <Grid
        noGutter
        css={{ gridTemplateColumns: '120px 1fr', gridGap: '$s100' }}
      >
        <GridColumn css={{ gridArea: '1 / 1 / auto / span 1' }}>
          <ProductCardImage
            image={image}
            premiumImage={premiumMainImage}
            youSaveSticker={undefined}
            imageScalingType={scalingPreset}
            inCart={true}
          />
        </GridColumn>

        <GridColumn css={{ gridArea: '1 / 2 / span 1 / span 1' }}>
          <Grid
            noGutter
            css={{ gridTemplateColumns: '1fr', w: '100%', gap: '$s150' }}
          >
            <GridColumn
              css={{
                gridArea: '1 / 1 / span 1 / span 1',
                flexDirection: 'column',
                g: 0,
              }}
            >
              <SpaceBetweenWrapper>
                <Title>
                  <InteractableContentLink
                    href={productUrl}
                    css={{ marginBottom: '$s25' }}
                    onClick={
                      modalState.display
                        ? () => {
                            toggleModal(false);
                          }
                        : undefined
                    }
                  >
                    {name}
                  </InteractableContentLink>

                  {nameOfAdditionalItems && (
                    <Premium>{'+ ' + nameOfAdditionalItems}</Premium>
                  )}
                </Title>
                <Remove>{remove()}</Remove>
              </SpaceBetweenWrapper>
            </GridColumn>

            {bpMin361 && (
              <GridColumn
                css={{
                  gridArea: '2 / 1 / span 1 / span 1',
                  alignItems: 'flex-end',
                }}
              >
                <SpaceBetweenWrapper>
                  {productType !== 'SubscriptionOfferProduct' &&
                    quantityStepper()}

                  {productType === 'SubscriptionOfferProduct' &&
                    inCheckout &&
                    startFrom()}

                  {productType === 'SubscriptionOfferProduct' &&
                    !inCheckout &&
                    subscriptionInfo()}

                  <PriceContainer>{price()}</PriceContainer>
                </SpaceBetweenWrapper>
              </GridColumn>
            )}
          </Grid>
        </GridColumn>

        {!bpMin361 && (
          <>
            <GridColumn css={{ gridArea: '2 / 1 / span 1 / span 1' }}>
              {productType !== 'SubscriptionOfferProduct' && quantityStepper()}

              {productType === 'SubscriptionOfferProduct' &&
                inCheckout &&
                startFrom()}
            </GridColumn>

            <GridColumn
              css={{
                gridArea: '2 / 2 / span 1 / span 1',
                placeSelf: 'center end',
              }}
            >
              {price()}
            </GridColumn>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default MiniCartProduct;

const Card = styled('article', {
  pb: '$s150',
  mb: '$s200',
  borderBottom: '$borders$default solid $interactiveBorderSeparator',
});

const SpaceBetweenWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  width: '100%',
});

const RemoveButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
});

const Remove = styled('div', {
  ml: '$s150',
  mt: '-10px',
});

const StartFrom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: 2,
  fontSize: '$fontSize50',
  lineHeight: '$lh16',
  select: {
    w: '100%',
    my: 1,
    border: 'solid 1px $staticBorderDefault',
    p: 1,
    '&:focus-visible': {
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
  },
});

const SelectWrapper = styled('div', {
  display: 'flex',
  gap: '$s50',
  alignItems: 'center',
});

const StartFromInfo = styled('div', {
  fontSize: '$fontSize75',
});
const SubscriptionInfo = styled('div', {
  fontSize: '$fontSize50',
  color: '$onSurface',
  mt: 1,
  maxWidth: '25ch',
});

const Title = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  a: {
    color: '$onSurface',
    fontFamily: 'fontBold',
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationThickness: ' 1px',
    },
  },
});

const Premium = styled('div', {
  fontSize: '$fontSize50',
  lineHeight: '$lh16',
});

const PriceContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Price = styled('div', {
  color: '$onSurface',
  display: 'flex',
  flexDirection: 'column',
  gap: '$s25',
  justifyContent: 'flex-end',
});

const WasPrice = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  textDecoration: 'line-through',
  fontSize: '$fontSize50',
  color: '$onWasPrice',
  whiteSpace: 'nowrap',
});

const NowPrice = styled('div', {
  fontFamily: 'fontBold',
  fontSize: '$fontSize200',
  whiteSpace: 'nowrap',
});

const Currency = styled('span', {
  fontSize: '$fontSize50',
});
